<template>
  <validation-observer #default="{ handleSubmit, invalid }" ref="refFormObserver">
    <b-form class="list-view product-checkout" @submit.prevent="handleSubmit(onSubmit)">
      <!-- Left Form -->
      <div>
        <b-card v-if="isMasterCompany" no-body>
          <b-card-header class="flex-column align-items-start">
            <b-card-title>{{ $t('address.company_select') }}</b-card-title>
            <b-card-text class="text-muted mt-25">
              {{ $t('address.company_select_placeholder') }}
            </b-card-text>
          </b-card-header>
          <b-card-body>
            <b-form-group :label="$t('company.transaction_list.company')" label-for="company_select">
              <treeselect @select="getCompanyAddresses" id="company_select" v-model="subCompanyID" :options="subCompanies" :placeholder="$t('company.transaction_list.company_placeholder')" />
            </b-form-group>
          </b-card-body>
        </b-card>

        <b-card no-body>
          <b-card-header class="flex-column align-items-start">
            <b-card-title>{{ $t('address.invoice_address') }}</b-card-title>
            <b-card-text class="text-muted mt-25">
              {{ $t('address.invoice_address_placeholder') }}
            </b-card-text>
          </b-card-header>
          <b-card-body>
            <b-card no-body text-variant="white" class="invoice-address-card bg-gradient-primary" v-for="item in invoiceAddressList" :key="item.CO_ENTITY_ADDRESS_ID">
              <h3 class="text-white">{{ item.ADDRESS1 }}</h3>

              <span> {{ taxOffice }} </span>
              <span v-if="item.ADDRESS2 !== ''">
                {{ item.ADDRESS2 }}
              </span>
              <span v-if="item.ADDRESS3 !== ''">
                {{ item.ADDRESS3 }}
              </span>
              <span>
                {{ `${item.ZIP_CODE} - ${item.TOWN_NAME} / ${item.CITY_NAME} ${item.COUNTRY_NAME}` }}
              </span>
              <span>
                {{ phoneFormat(item.MOBILE_TEL) }}
              </span>
              <span v-if="item.TEL1 !== ''">
                {{ phoneFormat(item.TEL1) }}
              </span>
              <span v-if="item.TEL2 !== ''">
                {{ phoneFormat(item.TEL2) }}
              </span>
            </b-card>
          </b-card-body>
        </b-card>

        <b-card no-body v-if="!useAsShipping">
          <b-card-header class="flex-column align-items-start">
            <b-card-title>{{ $t('address.shipping_address') }}</b-card-title>
            <b-card-text class="text-muted mt-25">
              {{ $t('address.shipping_address_placeholder') }}
            </b-card-text>
          </b-card-header>
          <b-card-body>
            <div class="shipping-address-container" v-if="shippingAddressList.length">
              <b-card
                no-body
                class="shipping-address-card cursor-pointer"
                :class="{
                  'selected-shipping bg-gradient-primary': selectedShipping === item.CO_ENTITY_ADDRESS_ID
                }"
                v-for="item in shippingAddressList"
                :key="item.CO_ENTITY_ADDRESS_ID"
                @click="selectShipping(item.CO_ENTITY_ADDRESS_ID)"
              >
                <!-- <feather-icon class="update-button cursor-pointer" icon="EditIcon" height="20" width="20" @click="openNewAddress(2, 2, item.CO_ENTITY_ADDRESS_ID)" /> -->
                <h3
                  :class="{
                    'text-white': selectedShipping === item.CO_ENTITY_ADDRESS_ID
                  }"
                >
                  {{ item.ADDRESS1 }}
                </h3>

                <span v-if="item.ADDRESS2 !== ''">
                  {{ item.ADDRESS2 }}
                </span>
                <span v-if="item.ADDRESS3 !== ''">
                  {{ item.ADDRESS3 }}
                </span>
                <span>
                  {{ `${item.ZIP_CODE} - ${item.TOWN_NAME} / ${item.CITY_NAME} ${item.COUNTRY_NAME}` }}
                </span>
                <span>
                  {{ phoneFormat(item.MOBILE_TEL) }}
                </span>
                <span v-if="item.TEL1 !== ''">
                  {{ phoneFormat(item.TEL1) }}
                </span>
                <span v-if="item.TEL2 !== ''">
                  {{ phoneFormat(item.TEL2) }}
                </span>
              </b-card>
            </div>
            <h3 v-else>
              {{ $t('address.empty_company_shipping_address_placeholder') }}
            </h3>
          </b-card-body>
        </b-card>
      </div>

      <!-- Right Col -->
      <div class="customer-card" @mouseenter="checkForm()">
        <b-card :title="$t('address.selected_addresses')">
          <b-card-text class="mb-0">
            {{ $t('address.invoice_address') }} :
            {{ selectedInvoiceAlias }}
          </b-card-text>
          <b-form-checkbox v-model="useAsShipping" checked="true" name="check-button" switch inline class="my-1" @change="toggleUseAsShipping">
            {{ $t('address.use_as_shipping_address') }}
          </b-form-checkbox>
          <hr />
          <b-card-text class="mb-0">
            {{ $t('address.shipping_address') }} :
            {{ selectedShippingAlias }}
          </b-card-text>
          <hr />
          <validation-provider #default="{ errors }" :name="$t('address.shipping_address')" rules="required">
            <b-form-input id="shipping" v-model="selectedShipping" hidden />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          <b-button variant="primary" block :disabled="invalid" @click="$emit('next-step')">
            {{ $t('address.go_to_pay') }}
          </b-button>
        </b-card>
      </div>
    </b-form>
    <!-- <address-modal ref="addressModal" :addressType="addressType" :processType="addressProcess" @selectShipping="selectShipping" /> -->
  </validation-observer>
</template>

<script>
import { BForm, BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BFormGroup, BFormInput, BButton, BFormInvalidFeedback, BFormTextarea, BOverlay, BFormCheckbox } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate/dist/vee-validate.full'
import { ValidationObserver } from 'vee-validate'
import { mapGetters } from 'vuex'
import addressModal from './addressModal.vue'
import { SET_SELECTED_SUB_COMPANY, SET_SHIPPING } from '@/store/services/checkout-service'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import '@/views/Report/components/scss/treeselect.scss'
import { GET_ALL_COMPANY } from '@/store/services/company-service'
import { ERP_GET_COMPANY_ADDRESS_LIST, ERP_GET_COMPANY_ADDRESS_LIST_BY_COMPANY_ID } from '@/store/services/erp-service'

export default {
  name: 'checkout-address',
  components: {
    // BSV
    BForm,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,
    BFormTextarea,
    BOverlay,
    BFormCheckbox,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    addressModal,
    Treeselect
  },
  data() {
    return {
      addressType: 2,
      addressProcess: 1,
      useAsShipping: false,
      subCompanies: undefined,
      subCompanyList: undefined
    }
  },
  created() {
    this.$store.dispatch(ERP_GET_COMPANY_ADDRESS_LIST)
    if (this.isMasterCompany) {
      this.getAllCompanies()
    }
  },
  computed: {
    ...mapGetters(['userAddress', 'currentUser', 'userInvoiceAddress', 'userShippingAddress', 'selectedShipping', 'selectedSubCompany', 'isMasterCompany', 'invoiceAddressList', 'shippingAddressList']),
    subCompanyID: {
      get() {
        return this.selectedSubCompany
      },
      set(val) {
        this.$store.commit(SET_SELECTED_SUB_COMPANY, val)
        if (!this.selectedSubCompany) {
          this.$store.dispatch(ERP_GET_COMPANY_ADDRESS_LIST)
        }
      }
    },
    taxOffice() {
      if (this.subCompanyID) {
        let company = this.subCompanyList?.find(x => x.company_id === this.subCompanyID)
        let number = company?.tax_number ?? ''
        let name = company?.tax_office_name ?? ''
        return `${number} - ${name}`
      } else {
        let number = this.currentUser?.company?.tax_number
        let name = this.currentUser?.company?.tax_office?.tax_office_name
        return `${number ?? ''} - ${name ?? ''}`
      }
    },
    selectedInvoiceAlias() {
      if (this.invoiceAddressList.length) {
        return this.invoiceAddressList[0].ADDRESS1 ?? ''
      }
    },
    selectedShippingAlias() {
      if (this.useAsShipping) {
        if (this.invoiceAddressList.length) {
          return this.invoiceAddressList[0].ADDRESS1 ?? ''
        }
        return ''
      } else {
        if (this.shippingAddressList.length) {
          return this.shippingAddressList?.find(x => x.CO_ENTITY_ADDRESS_ID === this.selectedShipping)?.ADDRESS1 ?? ''
        }
        return ''
      }
    }
  },
  methods: {
    openNewAddress(type, process, address_id = undefined) {
      this.addressType = type
      this.addressProcess = process
      this.$refs.addressModal.showModal(address_id)
    },
    selectShipping(val) {
      this.$store.commit(SET_SHIPPING, val)
    },
    checkForm() {
      this.$refs.refFormObserver.validate()
    },
    toggleUseAsShipping(val) {
      if (val) {
        this.$store.commit(SET_SHIPPING, this.invoiceAddressList[0].CO_ENTITY_ADDRESS_ID)
      } else {
        this.$store.commit(SET_SHIPPING, undefined)
      }
    },
    phoneFormat(phone) {
      return `+90 ${phone.slice(0, 3)} ${phone.slice(3, 6)} 
      ${phone.slice(6, 8)} ${phone.slice(8, 10)}`
    },
    getAllCompanies() {
      return new Promise(resolve => {
        this.subCompanies = []
        this.$store.dispatch(GET_ALL_COMPANY).then(response => {
          this.subCompanyList = response
          response.forEach(item => {
            this.subCompanies.push({
              id: item.company_id,
              label: item.company_name
            })
          })
          resolve()
        })
      })
    },
    getCompanyAddresses(item) {
      this.useAsShipping = false
      this.$store.dispatch(ERP_GET_COMPANY_ADDRESS_LIST_BY_COMPANY_ID, item.id)
      this.$store.commit(SET_SHIPPING, undefined)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/core/colors/palette-variables.scss';
.invoice-address-card {
  padding: 20px;
  .update-button {
    display: flex;
    position: absolute;
    top: 20px;
    right: 20px;
  }
}
.shipping-address-card {
  width: 350px;
  height: 200px;
  padding: 20px;
  background-color: rgb(242, 244, 244);
  box-shadow: rgb(25 42 70 / 13%) 2px 8px 10px 0px !important;
  .update-button {
    display: flex;
    position: absolute;
    top: 20px;
    right: 20px;
  }
}

.dark-layout {
  .shipping-address-card {
    background-color: rgb(22, 29, 49);
  }
}

.check-icon {
  display: flex;
  position: absolute;
  top: 10px;
  right: 10px;
}

.shipping-address-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0 10px 0 10px;
  gap: 10px;
  overflow-x: scroll;
  height: 240px;
}
</style>
