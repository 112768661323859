<template>
  <b-card class="w-100">
    <b-card-header>
      <h1>{{ $t("card.title") }}</h1>
    </b-card-header>
    <b-card-body>
      <b-row>
        <!-- #region Kart Bilgileri -->
        <b-col cols="12" md="6">
          <b-row>
            <!-- #region Kart Numarası -->
            <b-col cols="12">
              <b-form-group
                :label="$t('card.cardNumber')"
                :label-for="fields.cardNumber"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('card.cardNumber')"
                  :rules="`required|min:${cardNumberMaxLength}|max:${cardNumberMaxLength}`"
                >
                  <b-input-group>
                    <b-form-input
                      type="tel"
                      :id="fields.cardNumber"
                      v-model="cardNumber"
                      @focus="focusCardNumber"
                      @blur="blurCardNumber"
                      class="payment-input"
                      :state="errors.length > 0 ? false : null"
                      :maxlength="cardNumberMaxLength"
                      data-card-field
                      v-number-only
                      autocomplete="off"
                    />
                    <b-input-group-append is-text class="prepend">
                      <feather-icon
                        :icon="isCardNumberMasked ? 'EyeOffIcon' : 'EyeIcon'"
                        class="cursor-pointer"
                        title="Show/Hide card number"
                        tabindex="-1"
                        :disabled="paymentData.cardNumber === ''"
                        @click="toggleMask"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- #endregion -->
            <!-- #region Kart Üzerindeki İsim -->
            <b-col cols="12">
              <b-form-group
                :label="$t('card.cardName')"
                :label-for="fields.cardName"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('card.cardName')"
                  rules="required"
                >
                  <b-form-input
                    type="text"
                    :id="fields.cardName"
                    v-model="cardName"
                    class="payment-input text-uppercase"
                    :state="errors.length > 0 ? false : null"
                    data-card-field
                    v-letter-only
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- #endregion -->
            <!-- #region Kart SK-Ay -->
            <b-col cols="12" md="4">
              <b-form-group
                :label="$t('card.expirationDate')"
                :label-for="fields.cardMonth"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('card.month')"
                  rules="required"
                >
                  <b-form-select
                    class="payment-input"
                    :id="fields.cardMonth"
                    v-model="paymentData.cardMonth"
                    data-card-field
                    :class="errors.length > 0 ? 'is-invalid' : ''"
                  >
                    <option value disabled selected>
                      {{ $t("card.month") }}
                    </option>
                    <option
                      v-bind:value="n < 10 ? '0' + n : n"
                      v-for="n in 12"
                      v-bind:disabled="n < minCardMonth"
                      v-bind:key="n"
                    >
                      {{ generateMonthValue(n) }}
                    </option>
                  </b-form-select>
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- #endregion -->
            <!-- #region Kart SK-Yıl -->
            <b-col cols="12" md="4">
              <b-form-group :label-for="fields.cardYear">
                <validation-provider
                  #default="{ errors }"
                  :name="$t('card.year')"
                  rules="required"
                >
                  <b-form-select
                    class="payment-input"
                    style="margin-top: 21.39px"
                    :id="fields.cardYear"
                    v-model="paymentData.cardYear"
                    data-card-field
                    :class="errors.length > 0 ? 'is-invalid' : ''"
                  >
                    <option value disabled selected>
                      {{ $t("card.year") }}
                    </option>
                    <option
                      v-bind:value="$index + minCardYear"
                      v-for="(n, $index) in 12"
                      v-bind:key="n"
                    >
                      {{ $index + minCardYear }}
                    </option>
                  </b-form-select>
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- #endregion -->
            <!-- #region Kart CVV -->
            <b-col cols="12" md="4">
              <b-form-group :label="$t('card.CVV')" :label-for="fields.cardCvv">
                <validation-provider
                  #default="{ errors }"
                  :name="$t('card.CVV')"
                  rules="required"
                >
                  <b-form-input
                    type="tel"
                    :id="fields.cardCvv"
                    v-model="cardCvv"
                    class="payment-input"
                    :state="errors.length > 0 ? false : null"
                    maxlength="4"
                    data-card-field
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- #endregion -->
          </b-row>
        </b-col>
        <!-- #endregion -->
        <!-- #region Kart -->
        <b-col cols="12" md="6" class="mb-2">
          <Card :fields="fields" :isCardNumberMasked="isCardNumberMasked" />
        </b-col>
        <!-- #endregion -->
        <!-- #region Taksit Seçenekleri -->
        <b-col
          cols="12"
          class="mt-1"
          v-show="
            installmentOptions.length > 0 &&
            cardNumber.length === cardNumberMaxLength
          "
        >
          <h2>{{ $t("card.installment_title") }}</h2>
          <validation-provider name="selected_installment" rules="required">
            <b-form-radio-group
              id="selected_installment"
              v-model="selectedInstallment"
            >
              <table class="table table-striped w-100">
                <tbody>
                  <tr
                    v-for="(item, index) in installmentOptions"
                    :key="index"
                    class="cursor-pointer"
                    @click="setInstallment(item.count)"
                  >
                    <template v-if="checkMinAmount(item)">
                      <td>
                        <div class="d-flex">
                          <b-form-radio :value="item.count" />
                          <span v-if="item.count == 1">
                            {{ $t("card.single_shot") }}
                          </span>
                          <span v-else>
                            {{ $t("card.installment", { count: item.count }) }}
                          </span>
                        </div>
                      </td>
                      <td v-if="anyExtraInstallments">
                        <div class="d-flex" v-if="item.campaigns.length > 0">
                          <b-badge variant="light-success" pill>
                            {{
                              $t("card.extra_installment", {
                                count: item.campaigns[0].extra_installments,
                              })
                            }}
                          </b-badge>
                        </div>
                      </td>
                      <td>
                        <div
                          class="
                            d-flex
                            justify-content-center
                            align-items-center
                            flex-column
                          "
                        >
                          <span> {{ showCommission(item.commission) }} </span>
                          <span class="text-muted">
                            {{ $t("card.commission") }}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div
                          class="
                            d-flex
                            justify-content-center
                            align-items-center
                            flex-column
                          "
                        >
                          <span>
                            {{ installmentInfo(item.count, item.commission) }}
                          </span>
                          <span class="text-muted">
                            {{
                              item.count == 1
                                ? $t("card.single_shot")
                                : $t("card.monthly_payment")
                            }}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div
                          class="
                            d-flex
                            justify-content-center
                            align-items-center
                            flex-column
                          "
                        >
                          <span>{{ amountInfo(item.commission) }}</span>
                          <span class="text-muted">
                            {{ $t("card.total_payment") }}
                          </span>
                        </div>
                      </td>
                    </template>
                  </tr>
                </tbody>
              </table>
            </b-form-radio-group>
          </validation-provider>
        </b-col>
        <!-- #endregion -->
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import Card from "./Card.vue";
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardBody,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BFormSelect,
  BFormInvalidFeedback,
  BFormTextarea,
  BFormRadio,
  BFormRadioGroup,
  BBadge,
  BButton,
  BModal,
} from "bootstrap-vue";
import Cleave from "vue-cleave-component";
import { ValidationProvider } from "vee-validate/dist/vee-validate.full";
import { required } from "@validations";
import { mapGetters } from "vuex";
import {
  CHANGE_CARD_NUMBER,
  MASK_CARD_NUMBER,
  SET_CARD_CVV,
  SET_CARD_MONTH,
  SET_CARD_NAME,
  SET_CARD_YEAR,
  UNMASK_CARD_NUMBER,
  SET_INSTALLMENT,
  SET_DEFAULT_PAYMENT_DATA,
} from "@/store/services/payment-service";
import { priceFormat } from "@/assets/js/functions/currency-functions";

export default {
  name: "CardForm",
  directives: {
    "number-only": {
      bind(el) {
        function checkValue(event) {
          event.target.value = event.target.value.replace(/[^0-9]/g, "");
          if (event.charCode >= 48 && event.charCode <= 57) {
            return true;
          }
          event.preventDefault();
        }
        el.addEventListener("keypress", checkValue);
      },
    },
    "letter-only": {
      bind(el) {
        function checkValue(event) {
          if (event.charCode >= 48 && event.charCode <= 57) {
            event.preventDefault();
          }
          return true;
        }
        el.addEventListener("keypress", checkValue);
      },
    },
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BFormSelect,
    BFormInvalidFeedback,
    BFormTextarea,
    BFormRadio,
    BFormRadioGroup,
    BBadge,
    BButton,
    BModal,

    Cleave,
    ValidationProvider,

    Card,
  },
  data() {
    return {
      fields: {
        cardNumber: "v-card-number",
        cardName: "v-card-name",
        cardMonth: "v-card-month",
        cardYear: "v-card-year",
        cardCvv: "v-card-cvv",
      },
      minCardYear: new Date().getFullYear(),
      isCardNumberMasked: true,
      cardNumberMaxLength: 19,
      required,
    };
  },
  created() {
    this.$store.commit(SET_DEFAULT_PAYMENT_DATA);
  },
  computed: {
    ...mapGetters([
      "paymentData",
      "installment",
      "installmentOptions",
      "getLocale",
      "cartDetail",
    ]),
    cardNumber: {
      get() {
        return this.paymentData.cardNumber;
      },
      set(val) {
        this.$store.dispatch(CHANGE_CARD_NUMBER, val).then((response) => {
          this.cardNumberMaxLength = response;
        });
      },
    },
    cardName: {
      get() {
        return this.paymentData.cardName;
      },
      set(val) {
        this.$store.commit(SET_CARD_NAME, val);
      },
    },
    cardYear: {
      get() {
        return this.paymentData.cardYear;
      },
      set(val) {
        this.$store.commit(SET_CARD_YEAR, {
          cardYear: val,
          minCardMonth: this.minCardMonth,
        });
      },
    },
    cardMonth: {
      get() {
        return this.paymentData.cardMonth;
      },
      set(val) {
        this.$store.commit(SET_CARD_MONTH, val);
      },
    },
    cardCvv: {
      get() {
        return this.paymentData.cardCvv;
      },
      set(val) {
        this.$store.commit(SET_CARD_CVV, val);
      },
    },
    selectedInstallment: {
      get() {
        return this.installment;
      },
      set(val) {
        this.setInstallment(val);
      },
    },
    minCardMonth() {
      if (this.paymentData.cardYear === this.minCardYear)
        return new Date().getMonth() + 1;
      return 1;
    },
    anyExtraInstallments() {
      if (
        this.installmentOptions.findIndex((x) => x.campaigns.length > 0) !== -1
      ) {
        return true;
      }
    },
  },
  mounted() {
    this.maskCardNumber();
  },
  methods: {
    generateMonthValue(n) {
      return n < 10 ? `0${n}` : n;
    },
    invaildCard() {
      let number = this.paymentData.cardNumberNotMask.replace(/ /g, "");
      var sum = 0;
      for (var i = 0; i < number.length; i++) {
        var intVal = parseInt(number.substr(i, 1));
        if (i % 2 === 0) {
          intVal *= 2;
          if (intVal > 9) {
            intVal = 1 + (intVal % 10);
          }
        }
        sum += intVal;
      }
      if (sum % 10 !== 0) {
        alert(this.$t("cardForm.invalidCardNumber"));
      }
    },
    blurCardNumber() {
      if (this.isCardNumberMasked) {
        this.maskCardNumber();
      }
    },
    maskCardNumber() {
      this.$store.commit(MASK_CARD_NUMBER);
    },
    unMaskCardNumber() {
      this.$store.commit(UNMASK_CARD_NUMBER);
    },
    focusCardNumber() {
      this.unMaskCardNumber();
    },
    toggleMask() {
      this.isCardNumberMasked = !this.isCardNumberMasked;
      if (this.isCardNumberMasked) {
        this.maskCardNumber();
      } else {
        this.unMaskCardNumber();
      }
    },
    setInstallment(val) {
      this.$store.commit(SET_INSTALLMENT, val);
    },
    showCommission(rate) {
      return (
        new Intl.NumberFormat(this.getLocale, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(rate.split("%")[0]) + "%"
      );
    },
    installmentInfo(count, cms) {
      let comission = cms.split("%")[0];
      let amount =
        this.cartDetail.TOTAL_TRY_D_PWV + (this.cartDetail.TOTAL_TRY_D_PWV * comission) / 100;
      let monthly = amount / count;
      return priceFormat(monthly, this.getLocale, "TRY");
    },
    amountInfo(cms) {
      let comission = cms.split("%")[0];
      let amount =
        this.cartDetail.TOTAL_TRY_D_PWV + (this.cartDetail.TOTAL_TRY_D_PWV * comission) / 100;
      return priceFormat(amount, this.getLocale, "TRY");
    },
    checkMinAmount(ins) {
      let minAmount = ins?.minAmount;
      if (minAmount === null || minAmount === undefined) {
        minAmount = 0;
      } else {
        minAmount = minAmount.replace("TL", "").replace(",", "") * 1;
      }
      return this.cartDetail.TOTAL_TRY_D_PWV >= minAmount;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/credit-card.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";

.payment-modal {
  .modal-body {
    background: white !important;
    color: black !important;
    border-radius: 24px;
  }
}

.prepend {
  .input-group-text {
    height: 100% !important;
    border: 1px solid #d8d6de !important;
  }
}

.dark-layout {
  .prepend {
    .input-group-text {
      border-color: #3b4253 !important;
    }
  }
}
</style>

<style lang="scss" scoped>
.payment-input {
  height: 50px;
  font-size: 18px;
}
</style>
