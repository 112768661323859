<template>
  <b-card class="w-100">
    <b-card-header>
      <h1>{{ $t('shop.without_payment_title') }}</h1>
    </b-card-header>
    <b-card-body>
      <b-alert variant="success" :show="true">
        <h4 class="alert-heading">{{ $t('Warning') }}</h4>
        <div class="alert-body">
          <span>
            {{ $t('shop.without_payment_tooltip') }}
          </span>
        </div>
      </b-alert>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BAlert } from 'bootstrap-vue'

export default {
  name: 'without-payment',
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BAlert
  }
}
</script>
