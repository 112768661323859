<template>
  <div class="checkout-options">
    <b-card>
      <label class="section-label mb-1">
        {{ $t('shop.payment_type') }}
      </label>
      <b-form-radio-group v-model="type" :options="paymentTypes" />
      <hr />
      <div class="d-flex justify-content-between align-items-center mb-1">
        <label class="section-label ">
          {{ $t('shop.payment_detail') }}
        </label>
        <label v-if="cart.length" @click="clearCart()" class="section-label mb-1 text-danger small cursor-pointer">
          {{ $t('shop.swal.clear_cart_title') }}
        </label>
      </div>
      <div class="price-details">
        <table class="w-100">
          <thead>
            <tr>
              <th class="w-100"></th>
              <th class="text-center">USD</th>
              <th class="text-center">TRY</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th class="pb-1">{{ $t('shop.total') }}</th>
              <td class="px-2 text-right text-nowrap font-weight-bolder">{{ cartDetail.USD_SHOW }}</td>
              <td class="px-2 text-right text-nowrap font-weight-bolder text-warning">{{ cartDetail.TOTAL_TRY_SHOW }}</td>
            </tr>
            <!-- <tr v-if="type === 1">
              <th class="pb-1">{{ `${$t('shop.discount')}(%7)` }}</th>
              <td class="px-2 text-right text-nowrap font-weight-bolder">{{ cartDetail.DISCOUNT_SHOW }}</td>
              <td class="px-2 text-right text-nowrap font-weight-bolder text-warning">{{ cartDetail.DISCOUNT_TRY_SHOW }}</td>
            </tr> -->
            <tr class="border-bottom" style="border-width: 2px!important;">
              <th class="pb-1">{{ `${$t('shop.vat')}(%${cartDetail.VAT_RATE})` }}</th>
              <td class="px-2 text-right text-nowrap font-weight-bolder">{{ type === 1 ? cartDetail.USD_D_VAT_SHOW : cartDetail.USD_VAT_SHOW }}</td>
              <td class="px-2 text-right text-nowrap font-weight-bolder text-warning">{{ type === 1 ? cartDetail.TOTAL_TRY_D_VAT_SHOW : cartDetail.TOTAL_TRY_VAT_SHOW }}</td>
            </tr>
            <tr>
              <th>{{ $t('shop.order_total') }}</th>
              <td class=" px-2 text-right text-nowrap font-weight-bolder">{{ type === 1 ? cartDetail.TOTAL_USD_D_PWV_SHOW : cartDetail.TOTAL_USD_PWV_SHOW }}</td>
              <td class=" px-2 text-right text-nowrap font-weight-bolder text-warning">{{ type === 1 ? cartDetail.TOTAL_TRY_D_PWV_SHOW : cartDetail.TOTAL_TRY_PWV_SHOW }}</td>
            </tr>
          </tbody>
        </table>
        <!-- #region Ödeme Yap -->
        <b-button @click="pay()" :disabled="buttonState" variant="primary" block class="mt-1">
          {{ $t('order.order_button_text') }}
        </b-button>
        <!-- #endregion -->
      </div>
    </b-card>
    <!-- #region Ödeme Modal -->
    <b-modal ref="paymentModal" size="sm" no-close-on-backdrop no-close-on-esc hide-header hide-footer centered>
      <b-card-text class="text-center">
        <span v-html="$t('payment.on_redirect')" />
        <br />
        <b-spinner class="mr-1 mt-1" variant="primary" />
      </b-card-text>
      <div id="modalContent" class="d-none" />
    </b-modal>
    <!-- #endregion -->
  </div>
</template>

<script>
import $ from 'jquery'
import { BCard, BFormRadioGroup, BButton, BCardText, BSpinner } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { CLEAR_CART_EVENT, GET_USER_CART, ORDER_COMPLETE, SET_PAYMENT_TYPE } from '@/store/services/checkout-service'

export default {
  name: 'checkout-amount-detail',
  props: {
    buttonState: { required: true }
  },
  components: { BCard, BFormRadioGroup, BButton, BCardText, BSpinner },
  created() {
    this.setType(1)
  },
  computed: {
    ...mapGetters(['cart', 'currency', 'getLocale', 'paymentType', 'cartDetail']),
    paymentTypes() {
      return [
        { text: this.$t('shop.payment_type_1'), value: 1 },
        //{ text: this.$t('shop.payment_type_2'), value: 2 },
        { text: this.$t('shop.payment_type_3'), value: 3 }
      ]
    },
    type: {
      get() {
        return this.paymentType
      },
      set(val) {
        this.setType(val)
      }
    }
  },
  methods: {
    setType(type) {
      this.$store.commit(SET_PAYMENT_TYPE, type)
    },
    pay() {
      switch (this.paymentType) {
        case 1:
          this.$refs.paymentModal.show()
          this.$store
            .dispatch(ORDER_COMPLETE)
            .then(response => {
              $('#modalContent').html(response)
            })
            .catch(err => {
              this.$refs.paymentModal.hide()
              this.$swal({
                title: this.$t('payment.error_title'),
                text: err.ErrorMSG,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary'
                },
                confirmButtonText: this.$t('payment.error_button_text'),
                buttonsStyling: false
              })
            })
          break

        case 2:
        case 3:
          this.$store.dispatch(ORDER_COMPLETE).then(response => {
            this.$store.dispatch(GET_USER_CART)
            this.$router.push({
              name: '/Shop/OrderThanks',
              params: {
                id: response.order_m_id
              }
            })
          })
          break
      }
    },
    clearCart() {
      this.$swal({
        title: this.$t('shop.swal.clear_cart_title'),
        html: this.$t('shop.swal.clear_cart_html'),
        icon: 'warning',
        customClass: {
          denyButton: 'btn btn-danger',
          confirmButton: 'btn btn-success mr-2'
        },
        showDenyButton: true,
        denyButtonText: this.$t('shop.swal.clear_cart_deny_text'),
        confirmButtonText: this.$t('shop.swal.clear_cart_confirm_text'),
        buttonsStyling: false
      }).then(res => {
        if (res.value) {
          this.$store.dispatch(CLEAR_CART_EVENT)
        }
      })
    }
  }
}
</script>
