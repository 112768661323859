<template>
  <b-overlay
    :show="!cartReady || !currencyReady"
    variant="dark"
    opacity="0.85"
    blur="2px"
    rounded="sm"
  >
    <div v-if="!cartReady">
      <!-- Buraya İstersek Sepet Yüklenirken Bir Şeyler Koyabiliriz -->
    </div>
    <div v-else-if="cart.length > 0">
      <form-wizard
        ref="refFormWizard"
        color="#7367F0"
        :title="null"
        :subtitle="null"
        :finish-button-text="$t('shop.complete_order')"
        :back-button-text="$t('shop.go_back')"
        hide-buttons
        class="checkout-form-wizard steps-transparent"
      >
        <!-- cart detail -->
        <tab-content
          :title="$t('shop.my_cart')"
          icon="feather icon-shopping-cart"
        >
          <cart ref="cartRef" @next-step="goNextTab()" />
        </tab-content>

        <!-- address -->
        <tab-content :title="$t('shop.address')" icon="feather icon-home">
          <address-phase @next-step="goNextTab()" />
        </tab-content>

        <!-- payment -->
        <tab-content
          :title="$t('shop.payment')"
          icon="feather icon-credit-card"
        >
          <payment @next-step="refFormWizard.value.nextTab()" />
        </tab-content>
      </form-wizard>
    </div>
    <div v-else class="d-flex flex-column align-items-center">
      <feather-icon icon="ShoppingCartIcon" height="100" width="100" />
      <h1 class="my-2">{{ $t("shop.empty_cart_placeholder") }}</h1>
      <div>
        <b-button variant="primary" class="btn-md mr-1" :to="{ path: '/' }">
          {{ $t("BackToHome") }}
        </b-button>
        <b-button variant="success" class="btn-md" :to="{ name: '/Shop/Shop' }">
          {{ $t("shop.go_to_shop") }}
        </b-button>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import { BButton, BOverlay } from "bootstrap-vue";
import { FormWizard, TabContent } from "vue-form-wizard";
import Payment from "./Payment/Index.vue";
import Cart from "./Cart.vue";
import AddressPhase from "./Address/Index.vue";
import { mapGetters } from "vuex";
import { GET_TRANSACTION_DETAIL } from "@/store/services/payment-service";

export default {
  components: {
    BButton,
    BOverlay,
    // 3rd Party
    FormWizard,
    TabContent,
    // SFC
    Payment,
    Cart,
    AddressPhase,
  },
  data() {
    return {
      paymentType: undefined,
    };
  },
  created() {
    if (this.$route.name === "CheckoutError") {
      this.$store
        .dispatch(GET_TRANSACTION_DETAIL, this.$route.params.id)
        .then((response) => {
          let text = response.detail_desc;
          this.$swal({
            title: this.$t("order.on_payment_error_title"),
            text: text,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-success",
            },
            confirmButtonText: this.$t("notifications.confirm_button_text"),
            buttonsStyling: false,
          });
        });
    }
  },
  computed: {
    ...mapGetters(["cart", "cartReady", "currencyReady"]),
  },
  methods: {
    goNextTab() {
      this.$refs.refFormWizard.nextTab();
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import "~vue-form-wizard/dist/vue-form-wizard.min.css";
</style>

<style lang="scss" scoped>
.checkout-form-wizard ::v-deep {
  .wizard-tab-content {
    box-shadow: none !important;
    background: transparent !important;
    padding: 0;
  }
}
</style>
