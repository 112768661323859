<template>
  <validation-observer ref="payment" v-slot="{ invalid }">
    <div class="list-view product-checkout">
      <!-- Left Card -->
      <card-form v-if="paymentType === 1" />
      <transaction v-if="paymentType === 2" />
      <without-payment v-if="paymentType === 3" />

      <amount-detail :buttonState="invalid" @mouseenter="checkForm()" />
    </div>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import CardForm from './Card/CardForm.vue'
import Transaction from './Transaction.vue'
import WithoutPayment from './WithoutPayment.vue'
import AmountDetail from './AmountDetail.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'checkout-payment',
  components: {
    ValidationObserver,

    CardForm,
    Transaction,
    AmountDetail,
    WithoutPayment
  },
  computed: {
    ...mapGetters(['paymentType'])
  },
  methods: {
    checkForm() {
      this.$refs?.payment?.validate()
    }
  }
}
</script>
