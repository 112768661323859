<template>
  <div class="list-view product-checkout mt-0">
    <!-- Products List -->
    <products />

    <!-- Checkout Options -->
    <div>
      <div class="checkout-options">
        <b-card>
          <div class="d-flex justify-content-between align-items-center ">
            <label class="section-label mb-1">
              {{ $t('shop.payment_detail') }}
            </label>
            <label v-if="cart.length" @click="clearCart()" class="section-label mb-1 text-danger small cursor-pointer">
              {{ $t('shop.swal.clear_cart_title') }}
            </label>
          </div>
          <!-- <b-input-group class="input-group-merge coupons"> -->
          <!-- <b-form-input placeholder="Coupons" /> -->
          <!-- <b-input-group-append is-text> -->
          <!-- <span -->
          <!-- id="input-coupons" -->
          <!-- class="input-group-text text-primary cursor-pointer" -->
          <!-- > -->
          <!-- Apply -->
          <!-- </span> -->
          <!-- </b-input-group-append> -->
          <!-- </b-input-group> -->
          <hr />
          <div class="price-details">
            <table class="w-100">
              <thead>
                <tr>
                  <th class="w-100"></th>
                  <th class="text-center">USD</th>
                  <th class="text-center">TRY</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th class="pb-1">{{ $t('shop.total') }}</th>
                  <td class="px-2 text-right text-nowrap font-weight-bolder">{{ cartDetail.USD_SHOW }}</td>
                  <td class="px-2 text-right text-nowrap font-weight-bolder text-warning">{{ cartDetail.TOTAL_TRY_SHOW }}</td>
                </tr>
                <tr class="border-bottom" style="border-width: 2px!important;">
                  <th class="pb-1">{{ `${$t('shop.vat')}(%${cartDetail.VAT_RATE})` }}</th>
                  <td class="px-2 text-right text-nowrap font-weight-bolder">{{ cartDetail.USD_VAT_SHOW }}</td>
                  <td class="px-2 text-right text-nowrap font-weight-bolder text-warning">{{ cartDetail.TOTAL_TRY_VAT_SHOW }}</td>
                </tr>
                <tr>
                  <th class=" pb-1">{{ $t('shop.order_total') }}</th>
                  <td class=" px-2 text-right text-nowrap font-weight-bolder">{{ cartDetail.TOTAL_USD_PWV_SHOW }}</td>
                  <td class=" px-2 text-right text-nowrap font-weight-bolder text-warning">{{ cartDetail.TOTAL_TRY_PWV_SHOW }}</td>
                </tr>
              </tbody>
            </table>
            <b-button variant="primary" @click="$emit('next-step')" block>
              {{ $t('shop.address_selection') }}
            </b-button>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton, BCard, BInputGroup, BFormInput, BInputGroupAppend } from 'bootstrap-vue'
import Products from './Products.vue'
import { mapGetters } from 'vuex'
import { CLEAR_CART_EVENT } from '@/store/services/checkout-service'

export default {
  components: {
    // BSV
    BButton,
    BCard,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,

    // SFC
    Products
  },
  computed: {
    ...mapGetters(['cart', 'currency', 'getLocale', 'cartDetail'])
  },
  methods: {
    clearCart() {
      this.$swal({
        title: this.$t('shop.swal.clear_cart_title'),
        html: this.$t('shop.swal.clear_cart_html'),
        icon: 'warning',
        customClass: {
          denyButton: 'btn btn-danger',
          confirmButton: 'btn btn-success mr-2'
        },
        showDenyButton: true,
        denyButtonText: this.$t('shop.swal.clear_cart_deny_text'),
        confirmButtonText: this.$t('shop.swal.clear_cart_confirm_text'),
        buttonsStyling: false
      }).then(res => {
        if (res.value) {
          this.$store.dispatch(CLEAR_CART_EVENT)
        }
      })
    }
  }
}
</script>

<style lang="scss">
.checkout-options {
  position: sticky;
  top: 174px;
  right: 2rem;
}

@media screen and (max-width: 1201px) {
  .checkout-options {
    position: inherit;
  }
}
</style>
