<template>
  <b-card class="w-100">
    <b-card-header>
      <h1>{{ $t('order.transaction_detail') }}</h1>
    </b-card-header>
    <b-card-body>
      <b-overlay :show="!ready" variant="dark" opacity="0.85" blur="2px" rounded="sm">
        <b-row v-if="ready && data">
          <b-col cols="12" md="6">
            <b-card no-body>
              <b-card-header>
                <h3>{{ $t('order.pre_transaction_state') }}</h3>
              </b-card-header>
              <b-card-body>
                <b-card-text class="d-flex justify-content-between align-items-center">
                  <span>{{ $t('order.transaction_total_debit') }}</span>
                  <span class="text-danger font-weight-bold">
                    {{ priceFormat(data.TOTAL_DEBIT) }}
                  </span>
                </b-card-text>
                <b-card-text class="d-flex justify-content-between align-items-center">
                  <span>{{ $t('order.transaction_total_credit') }}</span>
                  <span class="text-success font-weight-bold">
                    {{ priceFormat(data.TOTAL_CREDIT) }}
                  </span>
                </b-card-text>
                <hr />
                <b-card-text class="d-flex justify-content-between align-items-center">
                  <span>{{ $t('order.transaction_balance') }}</span>
                  <span :class="`text-${balanceStateClass()} font-weight-bold`">
                    {{ priceFormat(data.BALANCE) }}
                  </span>
                </b-card-text>
              </b-card-body>
            </b-card>
          </b-col>
          <!-- <b-col cols="12" md="6">
            <b-card no-body>
              <b-card-header>
                <h3>{{ $t('order.transaction_info') }}</h3>
              </b-card-header>
              <b-card-body>
                <b-card-text class="d-flex justify-content-between align-items-center">
                  <span>{{ $t('order.transaction_balance') }}</span>
                  <span :class="`text-${balanceStateClass()} font-weight-bold`">
                    {{ priceFormat(data.BALANCE) }}
                  </span>
                </b-card-text>
                <b-card-text class="d-flex justify-content-between align-items-center">
                  <span>{{ $t('shop.cart_total') }}</span>
                  <span class="text-danger font-weight-bold">
                    {{ cartDetail.TOTAL_SHOW }}
                  </span>
                </b-card-text>
                <hr />
                <b-card-text class="d-flex justify-content-between align-items-center">
                  <span>{{ $t('order.transaction_balance_after') }}</span>
                  <span :class="`text-${balanceAferClass()} font-weight-bold`">
                    {{ priceFormat(data.BALANCE_AFTER) }}
                  </span>
                </b-card-text>
              </b-card-body>
            </b-card>
          </b-col> -->
        </b-row>
        <b-alert :variant="orderStateClass()" :show="ready">
          <h4 class="alert-heading">{{ $t('order.transaction_payment_state') }}:</h4>
          <div class="alert-body">
            <span>
              {{ $t(`order.transaction_state_${orderState}`) }}
            </span>
          </div>
        </b-alert>
        <validation-provider rules="required">
          <input type="hidden" v-model="data" />
        </validation-provider>
      </b-overlay>
    </b-card-body>
  </b-card>
</template>

<script>
import { BRow, BCol, BCard, BCardHeader, BCardBody, BCardText, BOverlay, BAlert } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate/dist/vee-validate.full'
import { GET_BALANCE, GET_COMPANY_BALANCE } from '@/store/services/company-service'
import { mapGetters } from 'vuex'
import { priceFormat } from '@/assets/js/functions/currency-functions'

export default {
  name: 'transaction-payment',
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCardText,
    BOverlay,
    BAlert,
    ValidationProvider
  },
  data() {
    return {
      data: undefined,
      ready: false,
      orderState: 0 // 0 => Hesap Bilgilerine Ulaşılamadı, 1 => Yeterli, 2 => Yetersiz
    }
  },
  created() {
    if (this.selectedSubCompany) {
      this.$store
        .dispatch(GET_COMPANY_BALANCE, this.selectedSubCompany)
        .then(res => {
          /*this.data = response
        let temp = this.data.BALANCE - this.cartDetail.TOTAL
        if (temp > 0) {
          this.orderState = 1
        } else {
          this.orderState = 2
        }
        this.data.BALANCE_AFTER = Math.abs(temp)*/

          this.data = {
            TOTAL_DEBIT: 0,
            TOTAL_CREDIT: 0,
            BALANCE: 0,
            BALANCE_AFTER: 0
          }
          let temp = this.data.BALANCE - this.cartDetail.TOTAL
          this.data.BALANCE_AFTER = temp
          this.orderState = 1
        })
        .finally(() => {
          this.ready = true
        })
    } else {
      this.$store
        .dispatch(GET_BALANCE)
        .then(response => {
          /*this.data = response
          let temp = this.data.BALANCE - this.cartDetail.TOTAL
          if (temp > 0) {
            this.orderState = 1
          } else {
            this.orderState = 2
          }
          this.data.BALANCE_AFTER = Math.abs(temp)*/

          this.data = {
            TOTAL_DEBIT: 0,
            TOTAL_CREDIT: 0,
            BALANCE: 0,
            BALANCE_AFTER: 0
          }
          let temp = this.data.BALANCE - this.cartDetail.TOTAL
          this.data.BALANCE_AFTER = temp
          this.orderState = 1
        })
        .catch(() => {})
        .finally(() => {
          this.ready = true
        })
    }
  },
  computed: {
    ...mapGetters(['cartDetail', 'getLocale', 'selectedSubCompany'])
  },
  methods: {
    priceFormat(amount) {
      let temp = Math.abs(amount)
      return priceFormat(temp, this.getLocale, 'USD')
    },
    balanceState() {
      let temp = this.data.BALANCE
      if (temp > 0) {
        return 1
      } else if (temp < 0) {
        return 0
      } else {
        return 2
      }
    },
    balanceStateClass() {
      let temp = this.balanceState()
      switch (temp) {
        case 0:
          return 'danger'
        case 1:
          return 'success'
        case 2:
          return 'secondary'
      }
    },
    orderStateClass() {
      switch (this.orderState) {
        case 0:
          return 'warning'
        case 1:
          return 'success'
        case 2:
          return 'danger'
      }
    },
    balanceAferClass() {
      if (this.data.BALANCE_AFTER > 0) {
        return 'success'
      } else {
        return 'danger'
      }
    }
  }
}
</script>
