<template>
  <div class="checkout-items">
    <b-card v-for="product in cart" :key="product.product_id" class="ecommerce-card" no-body>
      <!-- Product Image -->
      <div class="item-img">
        <b-link
          :to="{
            name: '/Shop/Product',
            params: { id: product.product_id }
          }"
          target="_blank"
        >
          <b-img :src="productImage(product)" :alt="`${product.product_name}`" />
        </b-link>
      </div>

      <!-- Product Details: Card Body -->
      <b-card-body>
        <div class="item-name">
          <h6 class="mb-1">
            <b-link
              class="text-body"
              :to="{
                name: '/Shop/Product',
                params: { id: product.product_id }
              }"
              target="_blank"
            >
              {{ `${product.product_name} ${!!product.attribute_name ? `(${product.attribute_name})` : ''}` }}
            </b-link>
          </h6>
        </div>

        <div>
          <div class="item-wrapper mb-1">
            <h6 class="item-name font-weight-bolder">{{ $t('shop.barcode_tooltip') }}: {{ product.barcode }}</h6>
          </div>
          <div class="item-wrapper mb-1">
            <h6 class="item-name">{{ $t('shop.product_code_tooltip') }}: {{ product.product_code }}</h6>
          </div>
          <div class="item-wrapper mb-1">
            <h6 class="item-name">{{ $t('shop.unit') }}: {{ product.unit_code }}</h6>
          </div>
        </div>

        <span class="text-success mb-1">{{ $t('shop.avaible_in_stock') }}</span>
        <div class="item-quantity my-1">
          <b-form-group :label="$t('shop.quantity')" :label-for="`quantity-${product.product_id}`">
            <change-quantity :id="`quantity-${product.product_id}`" :productId="product.product_id" :maxAmount="product.qty_avaible < product.qty_order_max ? product.qty_avaible : product.qty_order_max" />
          </b-form-group>
        </div>
      </b-card-body>

      <!-- Product Options/Actions -->
      <div class="item-options text-center">
        <div class="item-wrapper">
          <div class="item-cost">
            <h4>
              {{ productPrice(product) }}
              <span>{{ $t('shop.vat_included') }}</span>
            </h4>
            <h4 class="item-price " v-b-tooltip.hover.v-warning.html.bottom :title="priceTooltip(product)">
              <span class="text-warning">( {{ productPriceTL(product) }} ) </span>
              <span>{{ $t('shop.vat_included') }}</span>
            </h4>
          </div>
        </div>
        <b-button variant="light" class="mt-1 remove-wishlist" @click="removeProductFromCartClick(product)">
          <feather-icon icon="XIcon" class="mr-50" />
          <span>{{ $t('shop.remove') }}</span>
        </b-button>
        <b-button variant="primary" class="btn-cart move-cart" @click="toggleProductInWishlist(product)">
          <feather-icon icon="BookmarkIcon" class="mr-50" />
          <span class="text-nowrap">{{ $t('shop.wish_list') }}</span>
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardBody, BLink, BImg, BButton, BBadge, VBTooltip, BFormGroup } from 'bootstrap-vue'
import ChangeQuantity from '@/views/Order/Shop/ChangeQuantity.vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import { mapGetters } from 'vuex'
import { convertTL, priceFormat, priceTooltip, priceWithVat } from '@/assets/js/functions/currency-functions'
import { REMOVE_CART_EVENT, UPDATE_CART_EVENT } from '@/store/services/checkout-service'

export default {
  components: {
    BCard,
    BCardBody,
    BLink,
    BImg,
    BButton,
    BBadge,
    BFormGroup,
    Swiper,
    SwiperSlide,
    ChangeQuantity
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  data() {
    return {
      swiperOptions: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  },
  computed: {
    ...mapGetters(['cart', 'currency', 'getLocale', 'brandLogo'])
  },
  methods: {
    productPrice(product) {
      let price = product.unit_price_tra * product.quantity
      price = priceWithVat(price, product.vat_rate)
      let type = product.currency_code
      return priceFormat(price, this.getLocale, type)
    },
    productPriceTL(product) {
      let price = product.unit_price_tra * product.quantity
      let rate = product.vat_rate
      let type = product.currency_code
      price = convertTL(price, type, this.currency)
      price = priceWithVat(price, rate)
      return priceFormat(price, this.getLocale, 'TRY')
    },
    priceTooltip(product) {
      let price = product.unit_price_tra * product.quantity
      let rate = product.vat_rate
      let type = product.currency_code
      let tooltip = priceTooltip(price, rate, type, this.currency, this.getLocale)

      let html = `<h4 class="text-white">${this.$t('shop.price_detail')}</h4>
        <table>`
      if (type === 'USD') {
        html += `
        <tr>
          <td class="text-left">
            <h5 class="text-white text-nowrap">USD ${this.$t('shop.total')}</h5>
          </td>
          <td class="text-right">
            <h4 class="text-white text-nowrap">${tooltip.LIST_SHOW}</h4>
          </td>
        </tr>`
      }
      html += `
          <tr>
            <td class="text-left">
              <h5 class="text-white text-nowrap">TRY ${this.$t('shop.total')}</h5>
            </td>
            <td class="text-right">
              <h4 class="text-white text-nowrap">${tooltip.TRY_SHOW}</h4>
            </td>
          </tr>
          <tr>
            <td class="text-left border-top" style="border-width:2px!important;padding-top:5px;border-color:white!important;">
              <h5 class="text-white text-nowrap">${this.$t('shop.vat')} (%${rate})</h5>
            </td>
            <td class="text-right border-top" style="border-width:2px!important;padding-top:5px;border-color:white!important;">
              <h4 class="text-white text-nowrap">${tooltip.TRY_VAT_SHOW}</h4>
            </td>
          </tr>
          <tr>
            <td class="text-left">
              <h5 class="text-white text-nowrap">${this.$t('shop.vat_included')}</h5>
            </td>
            <td class="text-right">
              <h4 class="text-white text-nowrap">${tooltip.TRY_PWV_SHOW}</h4>
            </td>
          </tr>
        </table>`
      return html
    },
    productImage(product) {
      let tempImages = JSON.parse(product.product_images)
      if (tempImages?.length) {
        return `https://${process.env.VUE_APP_API_HOST}/Image/GetImage?image_id=${tempImages[0].image_id}`
      }
      return this.brandLogo
    },
    changeQuantity(product) {
      this.$store.dispatch(UPDATE_CART_EVENT, {
        product_id: product.product_id,
        price_list_detail_id: product.price_list_d_id,
        quantity: product.quantity,
        cart_id: product.cart_id
      })
    },
    removeProductFromCartClick(product) {
      this.$store.dispatch(REMOVE_CART_EVENT, {
        product_id: product.product_id,
        price_list_detail_id: product.price_list_d_id,
        quantity: product.quantity,
        cart_id: product.cart_id
      })
    },
    toggleProductInWishlist(product) {}
  }
}
</script>
