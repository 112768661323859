<template>
  <b-modal
    ref="addressModal"
    hide-footer
    centered
    no-close-on-backdrop
    size="lg"
    :title="modalTitle"
  >
    <validation-observer
      ref="addressValidation"
      v-slot="{ invalid }"
      tag="form"
    >
      <b-row>
        <!-- Alias -->
        <b-col cols="12">
          <validation-provider
            #default="{ errors }"
            :name="$t('address.alias')"
            rules="required"
          >
            <b-form-group
              :label="$t('address.alias')"
              label-for="address-alias"
              class="mb-2"
            >
              <b-form-input
                id="address-alias"
                v-model="addressDetails.address_alias"
                trim
              />
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <!-- Country -->
        <b-col cols="12" md="4" class="mb-2">
          <b-form-group
            :label="$t('address.country')"
            label-for="address-country"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('address.country')"
              rules="required"
            >
              <b-overlay
                :show="!countryReady"
                variant="dark"
                opacity="0.85"
                blur="2px"
                rounded="sm"
              >
                <treeselect
                  id="address-country"
                  v-model="addressDetails.country_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="countries"
                  :class="errors.length > 0 ? 'is-invalid' : ''"
                  @select="countrySelect"
                />
              </b-overlay>
              <b-form-invalid-feedback
                :state="errors.length > 0 ? false : null"
              >
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- City -->
        <b-col cols="12" md="4" class="mb-2">
          <b-form-group
            :label="$t('address.city')"
            label-for="address-city"
            v-show="addressDetails.country_id"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('address.city')"
              rules="required"
            >
              <b-overlay
                :show="!cityReady"
                variant="dark"
                opacity="0.85"
                blur="2px"
                rounded="sm"
              >
                <treeselect
                  id="address-city"
                  v-model="addressDetails.city_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="cities"
                  :class="errors.length > 0 ? 'is-invalid' : ''"
                  @select="citySelect"
                />
              </b-overlay>
              <b-form-invalid-feedback
                :state="errors.length > 0 ? false : null"
              >
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- Town -->
        <b-col cols="12" md="4" class="mb-2">
          <b-form-group
            :label="$t('address.town')"
            label-for="address-town"
            v-show="addressDetails.city_id"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('address.town')"
              rules="required"
            >
              <b-overlay
                :show="!townReady"
                variant="dark"
                opacity="0.85"
                blur="2px"
                rounded="sm"
              >
                <treeselect
                  id="address-town"
                  v-model="addressDetails.town_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="towns"
                  :class="errors.length > 0 ? 'is-invalid' : ''"
                />
              </b-overlay>
              <b-form-invalid-feedback
                :state="errors.length > 0 ? false : null"
              >
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- Line 1 -->
        <b-col cols="12">
          <validation-provider
            #default="{ errors }"
            :name="`${$t('address.line')} 1`"
            rules="required|max:100"
          >
            <b-form-group
              :label="`${$t('address.line')} 1`"
              label-for="line1"
              class="mb-2"
            >
              <b-form-textarea
                id="line1"
                v-model="addressDetails.address_line1"
                :state="errors.length > 0 ? false : null"
                rows="2"
                no-resize
              />
              <b-form-invalid-feedback
                :state="errors.length > 0 ? false : null"
              >
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <!-- Line 2 -->
        <b-col cols="12">
          <validation-provider
            #default="{ errors }"
            :name="`${$t('address.line')} 2`"
            rules="max:100"
          >
            <b-form-group
              :label="`${$t('address.line')} 2`"
              label-for="line2"
              class="mb-2"
            >
              <b-form-textarea
                id="line2"
                v-model="addressDetails.address_line2"
                :state="errors.length > 0 ? false : null"
                rows="2"
                no-resize
              />
              <b-form-invalid-feedback
                :state="errors.length > 0 ? false : null"
              >
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <!-- Line 3 -->
        <b-col cols="12">
          <validation-provider
            #default="{ errors }"
            :name="`${$t('address.line')} 3`"
            rules="max:100"
          >
            <b-form-group
              :label="`${$t('address.line')} 3`"
              label-for="line3"
              class="mb-2"
            >
              <b-form-textarea
                id="line3"
                v-model="addressDetails.address_line3"
                :state="errors.length > 0 ? false : null"
                rows="2"
                no-resize
              />
              <b-form-invalid-feedback
                :state="errors.length > 0 ? false : null"
              >
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <!-- Post Code -->
        <b-col cols="12" md="6">
          <validation-provider
            #default="{ errors }"
            :name="$t('address.post_code')"
            rules="required"
          >
            <b-form-group
              :label="$t('address.post_code')"
              label-for="post-code"
              class="mb-2"
            >
              <b-form-input
                id="post-code"
                v-model="addressDetails.post_code"
                :state="errors.length > 0 ? false : null"
                type="text"
                trim
              />
              <b-form-invalid-feedback
                :state="errors.length > 0 ? false : null"
              >
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <!-- Phone -->
        <b-col cols="12" md="6">
          <validation-provider
            #default="{ errors }"
            :name="$t('address.phone')"
            rules="required|min:10|max:10"
          >
            <b-form-group
              :label="$t('address.phone')"
              label-for="phone"
              class="mb-2"
            >
              <cleave
                id="phone"
                v-model="addressDetails.phone"
                :options="phoneOptions"
                class="form-control"
                :class="{
                  'is-invalid': errors.length > 0,
                }"
              />
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <!-- Fax -->
        <b-col cols="12" md="6">
          <validation-provider
            #default="{ errors }"
            :name="$t('address.fax')"
            rules="min:10|max:10"
          >
            <b-form-group
              :label="$t('address.fax')"
              label-for="fax"
              class="mb-2"
            >
              <cleave
                id="fax"
                v-model="addressDetails.fax"
                :options="phoneOptions"
                class="form-control"
                :class="{
                  'is-invalid': errors.length > 0,
                }"
              />
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <!-- Email -->
        <b-col cols="12" md="6">
          <validation-provider
            #default="{ errors }"
            :name="$t('address.email')"
            rules="required|email"
          >
            <b-form-group
              :label="$t('address.email')"
              label-for="email"
              class="mb-2"
            >
              <b-form-input
                id="email"
                v-model="addressDetails.e_mail"
                :state="errors.length > 0 ? false : null"
                type="email"
                trim
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>

        <!-- Submit Button -->
        <b-col
          cols="12"
          class="d-flex justify-content-end"
          @mouseenter="checkForm()"
        >
          <b-button
            variant="primary"
            type="submit"
            :disabled="invalid || !buttonState"
            @click="saveAddress()"
          >
            {{ $t("address.save") }}
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormTextarea,
  BModal,
  BOverlay,
} from "bootstrap-vue";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import "@/views/Report/components/scss/treeselect.scss";
import Cleave from "vue-cleave-component";
import "cleave.js/dist/addons/cleave-phone.tr.js";
import { ValidationProvider } from "vee-validate/dist/vee-validate.full";
import { ValidationObserver } from "vee-validate";
import {
  CREATE_ADDRESS,
  GET_ADDRESS,
  GET_ALL_CITIES,
  GET_ALL_COUNTRIES,
  GET_ALL_TOWNS,
  SET_CITIES,
  SET_TOWNS,
  UPDATE_ADDRESS,
} from "@/store/services/address-service";
import { mapGetters } from "vuex";
export default {
  name: "checkout-adress-modal",
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormTextarea,
    BModal,
    BOverlay,

    Treeselect,

    Cleave,

    ValidationProvider,
    ValidationObserver,
  },
  props: {
    addressType: { type: Number, required: true },
    processType: { type: Number, required: true },
  },
  data() {
    return {
      addressDetails: {
        address_alias: "",
        country_id: undefined,
        city_id: undefined,
        town_id: undefined,
        address_line1: "",
        address_line2: "",
        address_line3: "",
        post_code: undefined,
        phone: "",
        fax: "",
        e_mail: "",
        company_address_id: undefined,
      },
      countryReady: false,
      cityReady: false,
      townReady: false,
      phoneOptions: {
        phone: true,
        phoneRegionCode: "TR",
      },
      buttonState: true,
    };
  },
  created() {
    this.$store.dispatch(GET_ALL_COUNTRIES).then(() => {
      this.countryReady = true;
    });
  },
  computed: {
    ...mapGetters(["countries", "cities", "towns", "currentUser"]),
    modalTitle() {
      if (this.addressType === 1) {
        return this.$t("address.update_invoice_title");
      } else {
        if (this.processType === 1) {
          return this.$t("address.new_shipping_title");
        } else {
          return this.$t("address.update_shipping_title");
        }
      }
    },
  },
  methods: {
    checkForm() {
      this.$refs.addressValidation.validate();
    },
    showModal(id = undefined) {
      this.$store.commit(SET_CITIES, []);
      this.$store.commit(SET_TOWNS, []);
      this.addressDetails = {
        address_alias: "",
        country_id: undefined,
        city_id: undefined,
        town_id: undefined,
        address_line1: "",
        address_line2: "",
        address_line3: "",
        post_code: undefined,
        phone: "",
        fax: "",
        e_mail: "",
        company_address_id: undefined,
      };
      if (id) {
        this.getAddress(id);
      }
      this.$refs.addressModal.show();
    },
    getAddress(id) {
      this.$store.dispatch(GET_ADDRESS, id).then((response) => {
        this.addressDetails = {
          address_alias: response.address_alias,
          country_id: response.country_id,
          address_line1: response.address_line1,
          address_line2: response.address_line2,
          address_line3: response.address_line3,
          post_code: response.post_code,
          phone: response.phone,
          fax: response.fax,
          e_mail: response.e_mail,
          company_address_id: response.company_address_id,
        };
        this.countrySelect({ id: response.country_id }).then(() => {
          this.addressDetails.city_id = response.city_id;
          this.citySelect({ id: response.city_id }).then(() => {
            this.addressDetails.town_id = response.town_id;
          });
        });
      });
    },
    countrySelect(node) {
      return new Promise((resolve) => {
        this.cityReady = false;
        this.addressDetails.city_id = undefined;
        this.addressDetails.town_id = undefined;
        this.$store.commit(SET_CITIES, []);
        if (node.id) {
          this.$store.dispatch(GET_ALL_CITIES, node.id).then(() => {
            this.cityReady = true;
            resolve();
          });
        }
        this.cityReady = true;
        resolve();
      });
    },
    citySelect(node) {
      return new Promise((resolve) => {
        this.townReady = false;
        this.addressDetails.town_id = undefined;
        this.$store.commit(SET_TOWNS, []);
        if (node.id) {
          this.$store.dispatch(GET_ALL_TOWNS, node.id).then(() => {
            this.cityReady = true;
            resolve();
          });
        }
        this.townReady = true;
        resolve();
      });
    },
    saveAddress() {
      this.buttonState = false;
      //Yeni Adres
      if (this.processType === 1) {
        let body = {
          ...this.addressDetails,
          address_type_id: this.addressType,
          company_id: this.currentUser.company_id,
        };
        this.$store.dispatch(CREATE_ADDRESS, body).then((response) => {
          this.buttonState = true;
          this.$refs.addressModal.hide();
        });
      }
      //Adres Güncelleme
      else {
        let body = {
          ...this.addressDetails,
          address_type_id: this.addressType,
          company_id: this.currentUser.company_id,
        };
        this.$store.dispatch(UPDATE_ADDRESS, body).then((response) => {
          this.buttonState = true;
          this.$refs.addressModal.hide();
          this.$emit("selectShipping", response);
        });
      }
    },
  },
  watch: {
    "addressDetails.country"(val) {
      if (val === undefined) {
        this.addressDetails.city_id = undefined;
        this.addressDetails.town_id = undefined;
      }
    },
    "addressDetails.city"(val) {
      if (val === undefined) {
        this.addressDetails.town_id = undefined;
      }
    },
  },
};
</script>
